.containere{
    display: flex;
    padding: 1.5rem;
    
}
.logo{
    display: flex;
    align-items: center;
    flex: 1;
    gap: 1rem;
}
.logo>img{
    width: 2.5rem;
    height: 2.5rem;
}
.logo>span{
    font-weight: 600;
    
}


.right{
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}
.menu{
    display: flex;
    gap: 2rem;
    font-weight: 500;
    list-style: none;
}
.menu>li:hover{
    cursor: pointer;
    color: lightpink;
    transition: .2s ease-in-out;
}
.search{
    border-radius: 20px;
    border: none;
    outline: none;
    padding: .7rem;
}
.bar{
    display: none;
}
@media screen and (max-width: 856px) {
.search{
    display: none;
}
.right{
    gap: 1rem;
}


}

@media screen and (max-width: 640px) {
.right{
    position: absolute;
    right: 2rem;
    z-index: 9999;
    background: white;
    color: black;
    border-radius: 5px;
    flex-direction: column;
    padding: 1rem;


}
.menu{
    flex-direction: column;
    margin-left: -2rem;
    display: none;

}
.bar{
    display: block;
}
.search{
display: none;
}
.ic{
    display: none;
}
}