.cFooterWrapper{
    width: 100%;
    margin-top: 5rem;
}
.cFooterWrapper>hr{
    width: 100%;
   background: white;
    border: none;
    height: 1px;
    
}
.cFooter{
    display: flex;
    width: 100%;
    justify-content: space-around;
    
}
.Logo{
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin-top: 3rem;
}
.Logo>img{
    width: 2.5rem;
    height: 2.5rem;
}
.Logo>span{
    font-weight: 600;
    font-size: 1rem;
}

.block{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 12rem;
}
.detail{
    display: flex;
    flex-direction: row;
    gap: 2rem;
    width: inherit;
}
.pngLine{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    
    
}
.detail>:nth-child(2){
    font-weight: 600;
    font-size: 1rem;
}
.copywrite{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    margin-top: 3rem;
}
@media screen and (max-width: 856px) {
.block {
        display: flex;
        flex-direction: column;
        gap: .1rem;
        max-width: 8rem;
    }
    h1{
        font-size: 1.5rem;
    }
}
@media screen and (max-width: 768px) {
    .cFooter{
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: flex-start;
        font-size: 1.7rem;
        gap: 2rem;
    }
}
@media screen and (max-width: 640px) {
.cFooter{
    justify-content: center;
}
.Logo{
    display: none;
}
.copywrite{
    align-items: center;
    justify-content: center;
    text-align: center;
}
}